<template>
<div class="robotics_full_div">

  <b-embed type="iframe" class="robotics_iframe" aspect="16by9" src="https://eltest.internal.lionsbot.io/" allowfullscreen/>

  
</div>
</template>

<script>

import {
  BEmbed
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';



export default {
  components: {
    BEmbed
    
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    
      
    }
  },
  methods : {

  },
  mounted(){
  }
}
</script>
